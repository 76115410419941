import 'reflect-metadata';

// Styles setup
import './styles/index.scss';
import { GameName } from 'cfx/base/game';
import { setCurrentGameName } from 'cfx/base/gameRuntime';

const isForceRedM = __CFXUI_DEV__ && window.location.search.includes('__FORCE_REDM__');
const isRedMHost = window.location.hostname.includes('servers.redm.net');

// Set this as early as possible
setCurrentGameName(
  (isForceRedM || isRedMHost)
    ? GameName.RedM
    : GameName.FiveM,
);

// import { isExternalUrl } from 'cfx/utils/links';

// import { serversList } from './serversList';

// Install all anchors click intercepter to properly handle clicks on external links
// document.addEventListener('click', (event: MouseEvent) => {
//   const target: HTMLElement = event.target as any;

//   // const isA = target.matches('a');
//   // const isAChild = target.matches('a *');

//   // if (!isA && !isAChild) {
//   //   return;
//   // }

//   // const link = isA
//   //   ? target.getAttribute('href')
//   //   : target.closest('a')?.getAttribute('href');

//   if (link && isExternalUrl(link)) {
//     event.preventDefault();
//     serversList.invokeNative('openUrl', link);
//   }
// });
