import { inject, injectable, named, optional } from 'inversify';
import { makeAutoObservable, observable } from 'mobx';

import { ServicesContainer, useService } from 'cfx/base/servicesContainer';
import { IAnalyticsService } from 'cfx/common/services/analytics/analytics.service';
import { ScopedLogger } from 'cfx/common/services/log/scopedLogger';
import { IServersService } from 'cfx/common/services/servers/servers.service';
import { IServersConnectService } from 'cfx/common/services/servers/serversConnect.service';
import { serverAddress2ServerView } from 'cfx/common/services/servers/transformers';
import { IServerView } from 'cfx/common/services/servers/types';
import { fastRandomId } from 'cfx/utils/random';
import { RichEvent } from 'cfx/utils/types';

import { ConnectState } from './connect/state';
import { serversList } from '../../serversList';
import { ServersListEvents } from '../../serversList.events';
import { IConvarService, KnownConvars } from '../convars/convars.service';

export function registerServersListServersConnectService(container: ServicesContainer) {
  container.registerImpl(IServersConnectService, ServersListServersConnectService);
}

export function useServersListServersConnectService() {
  return useService(ServersListServersConnectService);
}

@injectable()
class ServersListServersConnectService implements IServersConnectService {
  @inject(IAnalyticsService) @optional()
  protected readonly analyticksSerivce: IAnalyticsService;

  public get canConnect(): boolean {
    return this._server === null;
  }

  private _state: ConnectState.Any | null = null;
  public get state(): ConnectState.Any | null {
    return this._state;
  }
  private set state(state: ConnectState.Any | null) {
    this._state = state;

    if (state && this.analyticksSerivce && this.server) {
      const analyticsService = this.analyticksSerivce;
      const {
        server,
      } = this;

      queueMicrotask(() => {
        switch (state.type) {
          case 'connecting': {
            analyticsService.trackEvent({
              action: 'Connecting',
              properties: {
                category: 'Server',
                label: `${server.hostname} (${server.id})`,
              },
            });
            analyticsService.trackEvent({
              action: 'ConnectingRaw',
              properties: {
                category: 'Server',
                label: server.id,
              },
            });
          }
        }
      });
    }
  }

  private _resolvingServer: boolean = false;
  public get resolvingServer(): boolean {
    return this._resolvingServer;
  }
  private set resolvingServer(resolvingServer: boolean) {
    this._resolvingServer = resolvingServer;
  }

  private _server: IServerView | null = null;
  public get server(): IServerView | null {
    if (!this._server) {
      return null;
    }

    return this.serversService.getServer(this._server.id) || this._server;
  }
  private set server(server: IServerView | null) {
    this._server = server;
  }

  private currentConnectNonce: string | null = null;

  get showServer(): boolean {
    if (this.state?.type === 'failed') {
      // blank 'fault' is usually internal code (including CnL failures)
      if (this.state.extra?.fault === 'cfx' || !this.state.extra?.fault) {
        return false;
      }
    }

    return true;
  }

  get showModal(): boolean {
    if (this.resolvingServer) {
      return true;
    }

    if (this.state) {
      return true;
    }

    return false;
  }

  constructor(
    @inject(IServersService)
    protected readonly serversService: IServersService,
    @inject(IConvarService)
    protected readonly convarService: IConvarService,
    @inject(ScopedLogger) @named('ServersListServersConnect')
    protected readonly logService: ScopedLogger,
  ) {
    makeAutoObservable(this, {
      // @ts-expect-error private
      _server: observable.ref,
    });

    serversList.onRich(ServersListEvents.backfillServerInfo, this.backfillServerInfo);

    serversList.onRich(
      ServersListEvents.connectTo,
      (event) => this.handleConnectTo(event.hostnameStr, event.connectParams),
    );
    serversList.onRich(ServersListEvents.connecting, () => {
      this.state = ConnectState.connecting();
    });
    serversList.onRich(ServersListEvents.connectStatus, (event) => {
      this.state = ConnectState.status(event.data);
    });
    serversList.onRich(ServersListEvents.connectFailed, (event) => {
      this.state = ConnectState.failed(event);
    });

    serversList.on('connectCard', (event: IConnectCard) => {
      this.state = ConnectState.card(event.data);
    });

    serversList.on('connectBuildSwitchRequest', (event: IConnectBuildSwitchRequest) => {
      this.state = ConnectState.buildSwitchRequest(event.data);
    });
    serversList.on('connectBuildSwitch', (event: IConnectBuildSwitch) => {
      this.state = ConnectState.buildSwitchInfo(event.data);
    });
  }

  private readonly handleConnectTo = (address: string, connectParams = '') => {
    if (connectParams) {
      try {
        const parsed = new URL(`?${connectParams}`, 'http://dummy');

        const truthy = ['true', '1'];

        const forceStreamerMode = parsed.searchParams.get('streamerMode') || 'false';

        if (truthy.includes(forceStreamerMode)) {
          this.convarService.setBoolean(KnownConvars.streamerMode, true);
        }
      } catch (e) {
        // no-op
      }
    }

    this.connectTo(address);
  };

  async connectTo(serverOrAddress: string | IServerView): Promise<void> {
    if (this.currentConnectNonce) {
      console.warn('Already connecting somewhere');

      return;
    }

    this.resolvingServer = true;
    this.server = await this.resolveServer(serverOrAddress);
    this.resolvingServer = false;

    // Set fake connecting state so UI will appear immediately
    if (!this.state) {
      this.state = { type: 'connecting' };
    }

    this.currentConnectNonce = fastRandomId();
  }

  get canCancel(): boolean {
    if (!this.state) {
      return false;
    }

    if (this.state.type === 'status' && !this.state.cancelable) {
      return false;
    }

    return true;
  }
  readonly cancel = () => {
    if (!this.canCancel) {
      return;
    }

    this.state = null;
    this.server = null;
    this.currentConnectNonce = null;
  };

  // TODO: Make it fail when server address is invalid
  private async resolveServer(serverOrAddress: string | IServerView): Promise<IServerView> {
    if (typeof serverOrAddress === 'string') {
      const server = await this.serversService.loadServerLiveData(serverOrAddress);

      return server || serverAddress2ServerView(serverOrAddress);
    }

    return this.serversService.loadServerLiveData(serverOrAddress);
  }

  private readonly backfillServerInfo = async (
    event: RichEvent.Payload<typeof ServersListEvents.backfillServerInfo>,
  ) => {
    if (this.currentConnectNonce === event.data.nonce) {
      const historyList = this.serversService.getHistoryList();

      if (historyList && this.server) {
        await historyList.addHistoryServer(await historyList.serverView2HistoryServer(this.server, event.data.server));
      }
    }

    this.currentConnectNonce = null;
  };
}

interface IConnectBuildSwitchRequest {
  data: ConnectState.DataFor<ConnectState.BuildSwitchRequest>;
}

interface IConnectBuildSwitch {
  data: ConnectState.DataFor<ConnectState.BuildSwitchInfo>;
}

interface IConnectCard {
  data: ConnectState.DataFor<ConnectState.Card>;
}
