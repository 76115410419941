import { makeAutoObservable } from 'mobx';
import React from 'react';

export const SearchBarState = new class SearchBarState {
  public readonly outletRef: React.RefObject<HTMLDivElement>;

  private _forceTransparentNav = false;
  get forceTransparentNav(): boolean {
    return this._forceTransparentNav;
  }

  private _ready = false;
  get ready(): boolean {
    return this._ready;
  }

  constructor() {
    makeAutoObservable(this);

    this.outletRef = React.createRef();
  }

  readonly setReady = () => {
    this._ready = true;
  };
  readonly setNotReady = () => {
    this._ready = false;
  };

  readonly setForceTransparentNav = () => {
    this._forceTransparentNav = true;
  };
  readonly setForceNormalNav = () => {
    this._forceTransparentNav = false;
  };
}();

export function useForceTransparentNav() {
  React.useLayoutEffect(() => {
    SearchBarState.setForceTransparentNav();

    return SearchBarState.setForceNormalNav;
  }, []);
}
