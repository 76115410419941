import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { NavBar } from '../../parts/NavBar/NavBar';
import { SearchBar } from '../../parts/SearchBar/SearchBar';
import { ThemeManager } from '../../parts/ThemeManager/ThemeManager';

import s from './ServersListApp.module.scss';

function ServersListUi() {
  return (
    <div className={s.root}>
      <NavBar />
      <SearchBar />
      <div className={s.outlet}>
        <Outlet />
      </div>
    </div>
  );
}
export const ServersListApp = observer(function ServersListApp() {
  return (
    <>
      <ThemeManager />
      <ServersListUi />
    </>
  );
});
