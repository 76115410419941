/* eslint-disable react/no-unescaped-entities */
import { Flex, ui, clsx } from '@cfx-dev/ui-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useBoundingClientRect, useOpenFlag } from 'cfx/utils/hooks';

import { SearchInputController } from '../SearchInputController';

import s from './Cheatsheet.module.scss';

const SHOW_TIPS_TIMEOUT = 500;
const HIDE_TIPS_TIMEOUT = 1000;

export interface CheatsheetProps {
  controller: SearchInputController;
  inputRef: React.RefObject<HTMLDivElement>;
}

function useTipsShown(controller: SearchInputController): boolean {
  const [tipsShow, showTips, hideTips] = useOpenFlag(true);
  const timerRef = React.useRef<SetTimeoutReturn | null>(null);

  React.useEffect(() => {
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current);
      timerRef.current = null;

      return;
    }

    const fn = controller.inputInFocus
      ? showTips
      : hideTips;
    const timeout = controller.inputInFocus
      ? SHOW_TIPS_TIMEOUT
      : HIDE_TIPS_TIMEOUT;

    timerRef.current = setTimeout(() => {
      timerRef.current = null;

      fn();
    }, timeout);

    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [controller.inputInFocus]);

  return tipsShow;
}

export const Cheatsheet = observer(function Cheatsheet(props: CheatsheetProps) {
  const {
    controller,
    inputRef,
  } = props;

  const tipsShow = useTipsShown(controller);

  const inputRect = useBoundingClientRect(inputRef);

  if (controller.shouldRenderWizard || !inputRect) {
    return null;
  }

  const rootStyle: React.CSSProperties = {
    '--x': ui.px(inputRect.x),
    '--y': ui.px(inputRect.bottom),
    '--w': ui.px(inputRect.width),
  } as any;

  const rootClassName = clsx(s.root, {
    [s.active]: tipsShow,
  });

  return (
    <div style={rootStyle} className={rootClassName}>
      <Flex gap="small">
        <span>
          <kbd>"role play"</kbd> for full string match,
        </span>

        <span>
          <kbd>tag:</kbd> to match tags,
        </span>

        <span>
          <kbd>locale:</kbd> to match locale,
        </span>
      </Flex>
    </div>
  );
});
