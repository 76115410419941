import { Box, Flex } from '@cfx-dev/ui-components';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { SearchBarState } from './SearchBarState';

export const SearchBar = observer(function NavBar() {
  React.useEffect(() => {
    SearchBarState.setReady();

    return SearchBarState.setNotReady;
  }, []);

  return (
    <Flex repell centered gap="large">
      <Box grow ref={SearchBarState.outletRef} />
    </Flex>
  );
});
